import React from 'react'
import { InfoCard } from 'components/InfoCard'


//TODO: Adopt this in case we need it in the future
export function NomadWarningBanner({ style }: { style?: any }) {
  return (
    <InfoCard
      style={style}
      title="Welcome to LeetSwap v2"
      description={
        <>
          <p>
            You are currently using the new version of LeetSwap. If you are looking for the old version, please <a href="https://old-linea.leetswap.finance" target="_blank" rel="noreferrer">click here</a>.
          </p>
        </>
      }
    />
  )
}
