/**
 *
 * https://github.com/diffusion-fi/v2-periphery/blob/main/scripts/config/config.ts
 *
 * When changing this also update: cypress/integration/contracts.ts
 *
 */

const PERIPHERY_TESTNET = {
  factory: '0x0000000000000000000000000000000000000000',
  weth9: '0x0000000000000000000000000000000000000000',
  router: '0x0000000000000000000000000000000000000000',
  mockUSDC: '0x0000000000000000000000000000000000000000',
  mockCANTO: '0x0000000000000000000000000000000000000000',
  mockATOM: '0x0000000000000000000000000000000000000000',
  mockOSMOSIS: '0x0000000000000000000000000000000000000000',
  multicall2: '0x0000000000000000000000000000000000000000',
  LHS: '0x0000000000000000000000000000000000000000',
  RHS: '0x0000000000000000000000000000000000000000',
  testerAddress: '0x0000000000000000000000000000000000000000',
  //0x851e5cE9fa409B731f980a5E00FA889b58D9037D
  // 0xA2c659531B15bFf2556Ea7E12D477D3C8761ACD9
  //0x95BF964f113a75a3974E8164105e6e5A8D743b87
  // 0x62154D72C202f04CA50a3Ba5630052D0348f337A
  rewardToken: '0x0000000000000000000000000000000000000000',
  secondaryRewardToken: '0x0000000000000000000000000000000000000000',
  miniChef: '0x0000000000000000000000000000000000000000',
  complexRewarderTime: '0x0000000000000000000000000000000000000000',
  diffusion: '0x0000000000000000000000000000000000000000',
}

const MAINNET_PERIPHERY = {
  factory: '0xa2899c776bAAF9925d432F83C950D5054A6CF59C',
  weth9: '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f',
  router: '0xd3Ea3BC1F5A3F881bD6cE9761cbA5A0833a5d737',
  multicall2: '0xfCD3842f85ed87ba2889b4D35893403796e67FF1',
  //
  minichef: '0x0000000000000000000000000000000000000000',
  diffusion: '0xff98dCf88D1ae42c7ebb835133AB57481d16076e',
}

export const MAINNET = {
  ...MAINNET_PERIPHERY,
  diffusionbar: '0x0000000000000000000000000000000000000000',
  airdrop: '0x0000000000000000000000000000000000000000',
}

export const TESTNET = {
  ...PERIPHERY_TESTNET,
  airdrop: '0x0000000000000000000000000000000000000000',
  diffusionbar: '0x0000000000000000000000000000000000000000',
}
